module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo.png"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"agapewien","accessToken":"MC5Yc0JhSFJFQUFHV1Q1dFhQ.SUDvv71mW--_vW9OXO-_vQLvv73vv73vv705Se-_ve-_ve-_ve-_vVx077-977-9dO-_vSrvv70v77-9B--_vQ","path":"/preview","previews":true,"pages":[{"type":"SplashScreen","match":"/","path":"/preview","component":"/Users/lukasmagerusan/Desktop/Agape Wien/agapewien/src/index.tsx"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
